<template>
	<form @submit.prevent="submit" class="broker-form reg">
		<h1 class="broker-form__title">
			{{ $t('web.agent') }}
			<span>
				{{ $t('web.registration') }}
			</span>
		</h1>

		<!-- <div class="broker-form__social">
			<h2 class="broker-form__social-title">
				{{ $t('web.login_social_title') }}
			</h2>
			<ul class="broker-form__social-list">
				<li v-for="item in list" :key="item.icon">
					<a :href="item.link" class="broker-form__social-link">
						<img
							:src="require(`@/assets/img/svg/broker/${item.icon}.svg`)"
							:alt="item.icon"
							decoding="async"
						/>
					</a>
				</li>
			</ul>
		</div>
		<div class="broker-form__or">
			<span>{{ $t('web.or') }}</span>
		</div> -->

		<div class="broker-form__input">
			<div class="broker-form__input-item">
				<label class="broker-form__input-label" for="reg-agency-email">
					{{ $t('web.email') }}
				</label>
				<or-input
					required
					v-model="form.email"
					id="reg-agency-email"
					class="main-type"
					:placeholder="$t('web.email')"
					@input="clearError('email')"
					:error="errors.email"
				/>
			</div>
			<div class="broker-form__input-item">
				<label class="broker-form__input-label" for="reg-agency-pas">
					{{ $t('web.password') }}
				</label>
				<or-input
					required
					v-model="form.password"
					id="reg-agency-pas"
					class="main-type"
					type="password"
					:placeholder="$t('web.password')"
					@input="clearError('password')"
					:error="errors.password"
				/>
			</div>
			<div class="broker-form__input-item">
				<label class="broker-form__input-label" for="reg-agency-pas-conf">
					{{ $t('web.confirm_password') }}
				</label>
				<or-input
					required
					id="reg-agency-pas-conf"
					v-model="form.password_confirmation"
					class="main-type"
					type="password"
					:placeholder="$t('web.password')"
					@input="clearError('password_confirmation')"
					:error="errors.password_confirmation"
				/>
			</div>
			<div class="broker-form__input-agreement">
				<or-checkbox @input="toggleAgree" :label="$t('web.reg_agreement')" />
			</div>
		</div>

		<or-button type="submit" class="broker-form__btn">
			<span>
				{{ $t('web.registration') }}
			</span>
		</or-button>

		<div class="broker-form__switch">
			<p>
				{{ $t('web.login_bottom_text') }}
				<router-link
					:to="{
						name: 'Login',
						params: { lang: $store.state.uiLanguage }
					}"
				>
					{{ $t('web.login') }}
				</router-link>
			</p>
		</div>
	</form>
</template>

<script>
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'

export default {
	data() {
		return {
			form: {
				email: '',
				password: '',
				password_confirmation: ''
			},
			errors: {},
			isAgreePolicy: false
		}
	},
	beforeMount() {
		Object.keys(this.form).forEach((key) => {
			// Set field errors
			this.errors[key] = { text: '', status: false }
		})
	},
	methods: {
		clearError(type) {
			this.errors[type].status = false
			this.errors[type].text = ''
		},
		submit() {
			const { commit } = this.$store
			const { password, password_confirmation } = this.form

			if (!this.isAgreePolicy) {
				commit('showNotif', {
					type: 'error',
					text: this.$t('notification.errorAgreePolicy')
				})

				return
			}

			if (password.length < 8) {
				this.errors.password.status = true

				commit('showNotif', {
					type: 'error',
					text: this.$t('web.agent_register_form_pas_length')
				})

				return
			}

			if (password.length > 20) {
				this.errors.password.status = true

				commit('showNotif', {
					type: 'error',
					text: this.$t('web.agent_register_form_pas_length_max')
				})

				return
			}

			if (password !== password_confirmation) {
				this.errors.password_confirmation.status = true

				commit('showNotif', {
					type: 'error',
					text: this.$t('web.agent_register_form_pas_same_as')
				})

				return
			}

			let isSubmit = true

			Object.entries(this.errors).forEach(([key, value]) => {
				if (key === 'status' && value) {
					isSubmit = false
				}
			})

			if (!isSubmit) {
				return
			}

			createRequestBroker(requestConfigs.POSTAccountBrokerSignUp, {
				jsonPayload: {
					...this.form
				}
			})
				.then(() => {
					this.$emit('next')
				})
				.catch((err) => {
					if (Object.keys(err.errors).length) {
						Object.entries(err.errors).forEach(([key, value]) => {
							if (this.errors[key]) {
								this.errors[key].status = true
								this.errors[key].text = value.join(', ')
							}
						})
					}
				})
		},
		toggleAgree(evt) {
			this.isAgreePolicy = evt.target.checked
		}
	},
	computed: {
		list() {
			return [
				{
					icon: 'apple',
					link: ''
				},
				{
					icon: 'google',
					link: ''
				}
				// {
				// 	icon: 'facebook',
				// 	link: ''
				// },
				// {
				// 	icon: 'linked-in',
				// 	link: ''
				// }
			]
		}
	}
}
</script>
